import { BASE_URL } from 'constants/origins';

export const getAllRoutes = () => ({
  url: `${BASE_URL}/routes`,
  method: 'GET',
});

export const getLastDate = () => ({
  url: `${BASE_URL}/date`,
  method: 'GET',
});

export const patchDate = ({ payload }) => ({
  url: `${BASE_URL}/routes/${payload}`,
  method: 'PATCH',
  body: payload.split('-').join('/'),
});

export const getFilteredRoutes = ({ payload }) => ({
  url: `${BASE_URL}/routes`,
  method: 'POST',
  body: payload,
});

export const getWorkforce = () => ({
  url: `${BASE_URL}/workforce`,
  method: 'GET',
});

export const getFilteredWorkforce = ({ payload }) => ({
  url: `${BASE_URL}/workforce/${payload}`,
  method: 'POST',
  body: payload.split('-').join('/'),
});

export const getVehicleSchedule = () => ({
  url: `${BASE_URL}/vehicle-schedule`,
  method: 'GET',
});

export const getFilteredSchedule = ({ payload }) => ({
  url: `${BASE_URL}/vehicle-schedule/${payload}`,
  method: 'POST',
  body: payload.split('-').join('/'),
});
