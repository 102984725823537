import { Typography } from '@mui/material';

const NodeTooltip = ({
  name, demand, ei, li, node_cap, coordinates, node_id,
}) => (
  <div>
    <table>
      <tr>
        <td>Name</td>
        <td>{name}</td>
      </tr>
      <tr style={{ background: '#e3e3e3' }}>
        <td>Demand</td>
        <td>{demand.toFixed(2)}</td>
      </tr>
      {/* <tr>
        <td>Star Time Window</td>
        <td>{ei}</td>
      </tr>
      <tr style={{ background: '#e3e3e3' }}>
        <td>End Time Window</td>
        <td>{li}</td>
      </tr>
      <tr>
        <td>Node Cap</td>
        <td>{node_cap}</td>
      </tr>
      <tr style={{ background: '#e3e3e3' }}>
        <td>Node Id</td>
        <td>{node_id}</td>
      </tr> */}
    </table>

    {/* <Typography style={{backgroundColor:'#fefefe'}}>
      {`Demand: ${demand}`}
    </Typography>
    <Typography>
      {`ei: ${ei}`}
    </Typography>
    <Typography>
      {`li: ${li}`}
    </Typography>
    <Typography>
      {`Node Cap: ${node_cap}`}
    </Typography>
    <Typography>
      {`Coordinates: ${coordinates}`}
    </Typography>
    <Typography>
      {`Node Id: ${node_id}`}
    </Typography> */}
  </div>
);

export default NodeTooltip;
