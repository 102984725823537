import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Divider, Tabs, Tab } from '@mui/material';
import { Notifications, AccountCircle } from '@mui/icons-material';
import { Flex } from 'components';
import { selectedRoute } from 'models/app';
import { withProps } from 'libraries/components';

import Logo from './ec.png';
import { useStyles } from './styles';

const NavigationBar = ({
  role,
  username,
  selectedRoute,
  routesList,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { push } = useHistory();

  const onTabChange = (_, value) => {
    setValue(value);
    push(value === 0 ? '/' : 'analysis');
  };

  useEffect(() => {
    const route = routesList?.[selectedRoute]?.id;
    setValue(route === 'analysis' ? 1 : 0);
  }, [selectedRoute]);

  return (
    <Flex className={classes.container}>
      <img src={Logo} alt="elta-courier" />

      <Tabs value={value} onChange={onTabChange}>
        {/* <Tabs value={value}> */}
        <Tab label="Data Entry" />
        <Tab label="Analysis" />
      </Tabs>
      {/* <Tabs
        value={value}
        onChange={handleChange}
      ></Tabs> */}

      <Flex style={{ marginRight: '24px' }}>
        <Notifications style={{ margin: 'auto', marginRight: '24px' }} />
        <Divider
          orientation="vertical"
          style={{
            margin: 'auto 0',
            marginRight: '24px',
            height: '40px',
            width: '2px',
            borderRadius: '50px',
            backgroundColor: 'black',
          }}
        />
        <Flex direction="column" className={classes.userName}>
          <div>{role}</div>
          <div>{username}</div>
        </Flex>
        <div
          className={classes.userIcon}
        >
          <AccountCircle />
        </div>
      </Flex>
    </Flex>
  );
};

export default withProps({
  selectedRoute,
})(NavigationBar);
