import { Typography, Paper } from '@mui/material';
import { withProps } from 'libraries/components';
import { routes } from 'models/app';
import { uniq } from 'lodash';

const RouteAnalysis = ({
  date,
  routes,
}) => {
  const totalDistance = Object
    .values(routes?.projected || {})
    ?.reduce((acc, cur) => acc + cur.total_distance, 0)?.toFixed(2) || 0;
  const vehicleNumber = Object.keys(routes?.projected || {}).length;
  const additionalInfo = Object.values(routes || {});

  const nodes = Object
    .values(routes?.projected || {})
    ?.map((datum) => datum?.routes
      ?.flatMap(({ node_sequence }) => node_sequence));
  const points = nodes?.reduce((acc, cur) => acc + cur?.length, 0) || 0;
  const stations = uniq(nodes.flat()).length - 1;

  return (
    <Paper style={{
      padding: '1vw',
      marginLeft: '0.8em',
      width: '19vw',
      position: 'sticky',
      right: 0,
      borderRadius: '14px',
      border: '2px solid #003c63',
    }}
    >
      <Typography gutterBottom style={{ fontSize: '18px' }}> ROUTE ANALYSIS </Typography>
      <hr color="#E8E8E8" />
      <Typography gutterBottom style={{ fontSize: '18px', marginBottom: '10px' }}>
        <b> SUMMARY  </b>
      </Typography>
      <Typography gutterBottom style={{ fontSize: '18px', marginBottom: '10px' }}>
        Vehicle Number: <b>{vehicleNumber}</b>
      </Typography>
      <Typography gutterBottom style={{ fontSize: '18px', marginBottom: '10px' }}>
        Distance Covered: <b> {totalDistance}  km</b>
      </Typography>
      <Typography gutterBottom style={{ fontSize: '18px', marginBottom: '10px' }}>
        Total Checkpoints: <b> {points}  </b>
      </Typography>
      <Typography gutterBottom style={{ fontSize: '18px', marginBottom: '10px' }}>
        Stations: <b> {stations}  </b>
      </Typography>
      <Typography gutterBottom style={{ fontSize: '18px', marginBottom: '10px' }}>
        Number of Routes: <b> {additionalInfo[2]?.['total_num_of_routes']}  </b>
      </Typography>
      <Typography gutterBottom style={{ fontSize: '18px', marginBottom: '10px' }}>
        Total Demand: <b> {additionalInfo[2]?.['total_demand']}  kg</b>
      </Typography>
      <Typography gutterBottom style={{ fontSize: '18px', marginBottom: '10px' }}>
        Vehicle Capacity: <b> {additionalInfo[2]?.['total_vehicle_capacity']}  kg</b>
      </Typography>
    </Paper>
  );
};

export default withProps({ routes })(RouteAnalysis);
