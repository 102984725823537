import { Divider } from '@mui/material';
import { Flex } from 'components';

const Tooltip = ({
  truck, duration, distance, route_sequence,
}) => (
  <Flex direction="column" style={{ justifyContent: 'center' }}>

    <table style={{ justifyContent: 'center' }}>
      <tr>
        <td key="1">Vehicle</td>
        <td key="2" style={{ textAlign: 'center' }}>{truck}</td>
      </tr>
      <tr style={{ background: '#e3e3e3' }}>
        <td>Total Duration</td>
        <td style={{ textAlign: 'center' }}>
          {duration}
          {' '}
          min
        </td>
      </tr>
      <tr>
        <td>Total Distance</td>
        <td style={{ textAlign: 'center' }}>
          {distance}
          {' '}
          km
        </td>
      </tr>
    </table>
  </Flex>
);

export default Tooltip;
