import { Box } from '@mui/material';
import classNames from 'clsx';
import { useStyles } from './styles';

const Flex = ({
  className = '',
  direction = 'row',
  center = false,
  wrap = false,
  children,
  shrink,
  grow,
  basis,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Box
      className={classNames(
        {
          [classes.flexColumn]: direction === 'column',
          [classes.flexRow]: direction === 'row',
          [classes.center]: center,
          [classes.wrap]: wrap,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Flex;
