import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  tableContainer: {
    '& input': {
      padding: '0 0.5rem',
      margin: 0,
      border: 0,
      outline: 'none',
      height: '28px',
      width: '100%',
      '&:focus': {
        boxShadow: '0 0 0 1px #0288d1',
      },
    },
  },
  pagination: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white',
  },
}));
