export const palette = {
  primary: {
    main: '#003C63',
    dark: '#012842',
    light: '#005578',
  },
  secondary: {
    main: '#005578',
  },
  info: {
    main: '#0D79DE',
  },
  error: {
    light: '#E57373',
    main: '#F44336',
    dark: '#BC3517',
  },
  success: {
    light: '#C5E0B4',
    main: '#4CAF50',
    dark: '#388E3C',
  },
  common: {
    white: 'white',
    black: 'black',
    grayLight: '#CCCCCC',
    grayDark: '#98999C',
  },
  text: {
    primary: '#4A4A4A',
    secondary: '#D3D4CE',
  },
};
