import { Typography, Paper } from '@mui/material';
import { withProps } from 'libraries/components';

const VehicleRoute = (

) => {
  const we = 2;
  return (
    <Paper style={{
      padding: '1vw',
      marginLeft: '0.8em',
      width: '19vw',
      position: 'sticky',
      left: 0,
      borderRadius: '14px',
      border: '2px solid #003c63',
    }}
    >
      <Typography gutterBottom style={{ fontSize: '18px' }}> VEHICLE ROUTE </Typography>
      <hr color="#E8E8E8" />
      <Typography gutterBottom style={{ fontSize: '18px', marginBottom: '10px' }}>
        <b> SUMMARY </b>
      </Typography>
      <Typography gutterBottom style={{ fontSize: '18px', marginBottom: '10px' }}>
        Vehicle 1 Max Capacity:
      </Typography>
      <Typography gutterBottom style={{ fontSize: '18px', marginBottom: '10px' }}>
        Distance Covered:
      </Typography>
      <Typography gutterBottom style={{ fontSize: '18px', marginBottom: '10px' }}>
        Total Checkpoints:
      </Typography>
      <hr color="#E8E8E8" />
      <Typography gutterBottom style={{ fontSize: '18px', marginBottom: '10px' }}>
        <b> ANALYSIS </b>
      </Typography>
      <Typography gutterBottom style={{ fontSize: '18px', marginBottom: '10px' }}>
        Start Point:
      </Typography>
      <Typography gutterBottom style={{ fontSize: '18px', marginBottom: '10px' }}>
        Checkpoint 01:
      </Typography>
      <Typography gutterBottom style={{ fontSize: '18px', marginBottom: '10px' }}>
        Cargo:
      </Typography>
      <Typography gutterBottom style={{ fontSize: '18px', marginBottom: '10px' }}>
        Mass:
      </Typography>
      <hr color="#E8E8E8" />
      <Typography gutterBottom style={{ fontSize: '18px', marginBottom: '10px' }}>
        Checkpoint 02:
      </Typography>
    </Paper>
  );
};

export default withProps()(VehicleRoute);
