import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import ModelProvider from './ModelProvider';

const Providers = ({
  theme,
  children,
}) => (
  <ModelProvider>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  </ModelProvider>
);

export default Providers;
