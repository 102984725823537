import { actionCreator } from 'libraries/system';

const createAction = actionCreator('APP');

export const updateSelectedRoute = createAction('UPDATE_SELECTED_ROUTE');

export const getAllRoutes = createAction('GET_ALL_ROUTES');
export const getLastDate = createAction('GET_LAST_DATE');
export const getWorkforce = createAction('GET_WORKFORCE');
export const getVehicleSchedule = createAction('GET_VEHICLE_SCHEDULE');

export const patchDate = createAction('PATCH_DATE');

export const getFilteredRoutes = createAction('GET_FILTERED_ROUTES');
export const getFilteredSchedule = createAction('GET_FILTERED_SCHEDULE');
export const getFilteredWorkforce = createAction('GET_FILTERED_WORKFORCE');
