import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ palette, classes }) => ({
  container: {
    height: '56px',
    display: 'flex',
    marginBottom: '10px',
    justifyContent: 'space-between',
    boxShadow: '0 1px 2px 0 rgba(201,201,201,0.5)',
    paddingLeft: '24px',
  },
  indicator: {
    height: '8px',
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',
    backgroundColor: '#003247',
  },
  labelIcon: {
    marginBottom: '0',
    minHeight: '0',
    paddingTop: '6px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
    '& svg': {
      marginBottom: '2px !important',
      marginLeft: '8px',
    },
  },
  tab: {
    textTransform: 'capitalize',
    fill: '#003247',
    textColor: '#003247',
  },
  paper: {
    padding: '16px 34px',
    boxShadow: '0 2px 4px 0 #CFCFCF',
    '& > div:last-child': {
      marginBottom: '0',
    },
  },
  innerRoute: {
    cursor: 'pointer',
    marginBottom: '8px',
  },
  userName: {
    fontSize: '12px',
    lineheight: '14px',
    margin: 'auto',
    marginRight: '8px',
    textTransform: 'capitalize',
  },
  userIcon: {
    width: '40px',
    height: '40px',
    margin: 'auto',
    '& svg': {
      width: '40px',
      height: '40px',
      fill: '#003247',
    },
  },
}));
