import React from 'react';
import MaterialBreadcrumbs from '@mui/material/Breadcrumbs';
import clsx from 'clsx';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { useStyles } from './style';

const Breadcrumbs = ({ labels, children, ...rest }) => {
  const classes = useStyles();
  const lastIndex = labels.length - 1;

  return (
    <MaterialBreadcrumbs
      className={classes.breadcrumbs}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      {...rest}
    >
      {labels.map((label, index) => (
        <div
          key={index}
          className={clsx({
            [classes.active]: index === lastIndex && labels.length > 1,
          })}
        >
          <span>{label}</span>
          {index === lastIndex && children}
        </div>
      ))}
    </MaterialBreadcrumbs>
  );
};

export default Breadcrumbs;
