import { useEffect } from 'react';
import { Typography, Paper } from '@mui/material';
import { Flex } from 'components';
import { withProps } from 'libraries/components';
import { getWorkforce, work, details } from 'models/app';
import ScatterPlot from '../ScatterPlot';

const WorkForceAnalytics = ({
  getWorkforce,
  work,
  details,
}) => {
  useEffect(() => {
    getWorkforce();
  }, []);
  return (
    <Paper style={{
      padding: '1vw',
      width: '77vw',
      position: 'sticky',
      right: 0,
      borderRadius: '14px',
      border: '2px solid #003c63',
    }}
    >
      <Typography gutterBottom style={{ fontSize: '18px' }}>  WORKFORCE ANALYTICS </Typography>

      <Flex gap="1vw">
        <div style={{ marginRight: '5em' }}>
          <Typography style={{ marginBottom: '35px', fontSize: '28px', marginTop: '16px' }} gutterBottom>
            <b> Totals </b>
          </Typography>
          <Typography gutterBottom>
            <b style={{ fontSize: '60px', color: '#33CC00' }}> {details?.totalWeight} Tn </b>
          </Typography>
          <Typography style={{ marginBottom: '35px' }} gutterBottom>
            Delivered
          </Typography>
          <Typography gutterBottom>
            <b style={{ fontSize: '60px', color: '#33CC00', marginTop: '32px' }}> {details?.requiredWorkforce} ppl </b>
          </Typography>
          <Typography gutterBottom>
            Required Workforce
          </Typography>
        </div>
        <ScatterPlot
          data={work}
        />

      </Flex>
    </Paper>
  );
};

export default withProps({
  getWorkforce,
  work,
  details,
})(WorkForceAnalytics);
