import { Provider } from 'react-redux';
import { combineReducers } from 'redux';
import { createEpicMiddleware, combineEpics } from 'redux-observable';

import configStore from 'libraries/configStore';

import { reducer as app, epics as appEpics } from 'models/app';
import { reducer as ui } from 'models/ui';

const ModelProvider = ({ options, children }) => {
  const rootReducer = combineReducers({
    ui,
    app,
  });

  const rootEpic = combineEpics(appEpics);

  const epicMiddleware = createEpicMiddleware({
    dependencies: { options },
  });

  const middleWares = [epicMiddleware];
  const store = configStore(rootReducer, {}, middleWares);

  epicMiddleware.run(rootEpic);

  return <Provider store={store}>{children}</Provider>;
};

export default ModelProvider;
