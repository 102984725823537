export const createAction = (type) => {
  const actionCreatorFunc = (payload) => ({
    type,
    payload,
  });

  actionCreatorFunc.type = type;

  return actionCreatorFunc;
};

export const actionCreator = (group) => (type) => {
  const action = createAction(`${group}/${type}`);

  action.success = createAction(`${group}/${type}_SUCCESS`);
  action.fail = createAction(`${group}/${type}_FAIL`);

  return action;
};
