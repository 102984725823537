import { withStyles as muiWithStyles } from '@mui/styles';

const withStyles = muiWithStyles(() => ({
  container: {
    '& .MuiPaper-root': {
      padding: 8,
    },
  },
}));

export default withStyles;
