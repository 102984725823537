import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ palette, classes }) => ({
  breadcrumbs: {
    fontSize: '32px',
    color: '#003247',
    font: 'bold',
    display: 'flex',
    alignItems: 'center',
    minHeight: '80px',
    lineHeight: '48px',
    fontWeight: 'bold',
    paddingLeft: '1vw',
  },
  active: {
    fontSize: '20px',
    lineHeight: '25px',
    display: 'flex',
    fontWeight: 'normal',

  },
}));
