import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  dialog: {
    '& > div > div': {
      width: '100%',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0 24px 0px 24px',
  },
}));
