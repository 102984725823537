import { useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import {
  map,
  tileLayer,
  polyline,
  latLng,
  icon,
  marker,
  layerGroup,
  control,
  LayersControl,
} from 'leaflet';
import polyUtil from 'polyline-encoded';

import { routes } from 'models/app';
import { withProps } from 'libraries/components';
import { Tooltip, NodeTooltip } from './components';

const greenIcon = icon({
  iconUrl:
    'https://img.icons8.com/material-sharp/48/000000/globe--v2.png',
  iconSize: [
    48, 48],
  iconAnchor: [12, 48],
  popupAnchor: [1, -34],
  shadowSize: [48, 48],
});

const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const createRoute = (routes, routesLayer, dash) => {
  Object.entries(routes || {})?.forEach(([vehicle, value]) => {
    value?.routes.forEach((route) => {
      let totalPolyline = [];
      route.polylines.forEach((pol) => {
        const points = polyUtil
          .decode(pol)
          .map(([lat, lon]) => latLng(lat, lon));
        totalPolyline = [...totalPolyline, ...points];
      });

      polyline(totalPolyline, {
        weight: 3, opacity: 1, color: getRandomColor(), dashArray: dash,
      })
        .bindTooltip(ReactDOMServer.renderToString(
          <Tooltip
            truck={vehicle}
            distance={route.distance_by_route}
            duration={route.travelled_duration_by_route}
          />,
        ), { opacity: 1 })
        .addTo(routesLayer);
      totalPolyline = [];
    });
  });
};
const createmarker = (nodes, clientsLayer) => {
  nodes?.forEach((node) => {
    marker(node.coordinates).bindTooltip(ReactDOMServer.renderToString(
      <NodeTooltip
        name={node.name}
        demand={node.demand}
      // ei={node.ei}
      //   li={node.li}
      //   node_cap={node.node_cap}
      //   coordinates={node.coordinates}
      //   node_id={node.node_id}
      />,
    ), { opacity: 1 }).addTo(clientsLayer);
  });
};
const Map = ({ routes }) => {
  useEffect(() => {
    const mymap = map('map').setView([37.995153, 23.733381], 11);
    const mapLayer = layerGroup();
    tileLayer(
      'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
    ).addTo(mapLayer);
    const normalMapLayer = layerGroup();
    tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    ).addTo(normalMapLayer);
    marker([37.987253, 23.716721], { icon: greenIcon }).addTo(mymap);

    const routesLayer = layerGroup();
    createRoute(routes.actual, routesLayer, 'actual', 0);
    createRoute(routes.projected, routesLayer, 'projected', 10);

    const clientsLayer = layerGroup();
    createmarker(routes.problem, clientsLayer);
    mapLayer.addTo(mymap);
    clientsLayer.addTo(mymap);
    routesLayer.addTo(mymap);
    const baseLayers = {
      Grey: mapLayer,
      Normal: normalMapLayer,
    };
    const overlays = {
      Locations: clientsLayer,
      Routes: routesLayer,
    };
    control.layers(baseLayers, overlays).addTo(mymap);

    return () => mymap.remove();
  }, [routes]);

  return (
    <div
      id="map"
      style={{
        width: '77vw',
        height: '73vh',
        borderRadius: '14px',
        border: '2px solid #003c63',
        marginTop: '98px',
      }}
    />
  );
};

export default withProps({
  routes,
})(Map);
