import Home from './home';
import Analysis from './analysis';

const routes = {
  analysis: {
    id: 'analysis',
    path: '/analysis',
    exact: true,
    component: Analysis,
    isPrivate: true,
    enabled: true,
  },
  home: {
    id: 'home',
    path: '/',
    exact: true,
    component: Home,
    isPrivate: true,
    enabled: true,
  },
};

export const routesList = Object.fromEntries(
  Object
    .entries(routes)
    .map(([key, { component, ...route }]) => [key, route]),
);

export default routes;
