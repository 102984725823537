import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: 'white',
    maxWidth: '80px',
    transition: 'all 0.4s ease',
    border: 'none',
    outline: 'none',
    width: (props) => (props.isOpen ? '80px' : '0px'),
    opacity: (props) => (props.isOpen ? '1' : '0'),
  },
  filterContainer: {
    // position: 'absolute',
    // top: 'calc(50% - 12px)',
    width: 'fit-content',
    borderRadius: '4px',
    transition: 'all 0.4s ease',
    border: (props) => (props.isOpen ? '1px solid black' : 'none'),
    backgroundColor: (props) => (props.isOpen ? 'white' : 'transparent'),
    '& svg': {
      width: '20px',
      height: '20px',
      // margin: 'auto',
      cursor: 'pointer',
      marginTop: '2px',
      fill: theme.palette.primary.main,
    },
  },
}));
