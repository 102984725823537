import {
  Dialog as MuiDialog,
  DialogTitle,
} from '@mui/material';

import { withProps } from 'libraries/components';
import { closeDialog, dialog } from 'models/ui';
import {
  EXAMPLE,
} from 'constants/dialog';

import { useStyles } from './styles';

const Dialog = ({ dialog, closeDialog }) => {
  const classes = useStyles();

  const handleClose = () => closeDialog();

  const Component = {
    [EXAMPLE]: () => <div>Hello World</div>,
  }[dialog.type] || (() => null);

  return (
    <MuiDialog
      fullScreen={dialog.fullScreen}
      open={dialog.isOpen}
      className={classes.dialog}
      onClose={handleClose}
    >
      <div className={classes.container}>
        <DialogTitle>{dialog.title}</DialogTitle>

        <Component
          props={dialog.props}
          closeDialog={handleClose}
        />
      </div>
    </MuiDialog>
  );
};

export default withProps({
  dialog,
  closeDialog,
})(Dialog);
