import { useEffect } from 'react';
import {
  axisBottom,
  axisLeft,
  scaleBand,
  scaleLinear,
  select,
} from 'd3';
// import { scaleOrdinal } from 'd3';

const statusMapping = {
  waiting: '#8B0000',
  loading: '#FF8C00',
  ongoing: '#4575B4',
  finished: 'white',
};

const keys = [
  {
    label: 'Transit', color: '#4575B4',
  },
  {
    label: 'Loading', color: '#FF8C00',
  },
  {
    label: 'Waiting', color: '#8B0000',
  },
  {
    label: 'Finished', color: 'white',
  },
];

const size = 20;

const GanttChart = ({ data }) => {
  const id = 'gantt';
  const margin = {
    top: 10,
    right: 20,
    bottom: 10,
    left: 60,
  };

  const width = 900 - margin.left - margin.right;
  const height = 450 - margin.top - margin.bottom;

  useEffect(() => {
    const svg = select(`#${id}`)
      .append('svg')
      .attr('width', 800 + width + margin.left + margin.right)
      .attr('height', 100 + height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const xScale = scaleLinear()
      .domain([0, 8])
      .range([0, width])
      .clamp(true);

    const yScale = scaleBand()
      .domain(data?.map((datum) => datum.vehicle_id))
      .range([0, height])
      .padding(0.5);

    svg.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${height})`)
      .call(axisBottom(xScale));

    svg.append('g')
      .attr('class', 'y-axis')
      .call(axisLeft(yScale).tickSize(0));

    // select('.x-axis')
    //   .selectAll('.tick > text')
    //   .attr('dy', '1.5em')
    //   .attr('x', '-18')
    //   .style('transform', 'rotate(-45deg)');

    // svg.selectAll('myRect')
    //   .data(data)
    //   .enter()
    //   .append('rect')
    //   .attr('x', 1)
    //   .attr('y', (d) => yScale(d))
    //   .attr('width', width)
    //   .attr('height', yScale.bandwidth())
    //   .attr('fill', (_, i) => (i % 2 === 0 ? '#fbfbfb' : 'white'));

    data?.forEach(({ vehicle_id, dataset }) => {
      svg.selectAll('myRect')
        .data(dataset)
        .enter()
        .append('rect')
        .attr('x', (d) => xScale(d.start))
        .attr('y', yScale(vehicle_id))
        .attr('rx', '2')
        .attr('width', (d) => {
          return xScale(d.end) - xScale(d.start)
        })
        .attr('height', yScale.bandwidth())
        .attr('fill', (d) => statusMapping[d.status]);
    });

    svg.selectAll('mydots')
      .data(keys)
      .enter()
      .append('rect')
      .attr('x', 1000)
      .attr('y', (d, i) => 175 + i * (size + 5))
      .attr('width', size)
      .attr('height', size)
      .style('fill', (d) => (d.color));

    svg.selectAll('mylabels')
      .data(keys)
      .enter()
      .append('text')
      .attr('x', 1000 + size * 1.2)
      .attr('y', (d, i) => 175 + i * (size + 5) + (size / 2))
      .style('fill', (d) => d.color)
      .text((d) => d.label)
      .attr('text-anchor', 'left')
      .style('alignment-baseline', 'middle');

    svg.append('text')
      .style('font-size', '16px')
      .attr('class', 'targetgoal')
      .attr('x', width - 40)
      .attr('y', height + 35)
      .text('hours');
    return () => svg?.node().parentElement.remove();

    // .
  }, [data]);

  return (
    <div id={id} />
  );
};

export default GanttChart;
