import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import Providers from 'providers';
import { Routes } from 'routes';
import { theme } from 'assets/theme';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <StrictMode>
    <Providers
      theme={theme}
    >
      <Routes />
    </Providers>
  </StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
