import { useEffect } from 'react';
import { Typography, Paper } from '@mui/material';
import { withProps } from 'libraries/components';
import { getVehicleSchedule, schedule } from 'models/app';
import GanttChart from './GanttChart';

const VehicleSchedule = ({
  getVehicleSchedule,
  schedule,
}) => {
  useEffect(() => {
    getVehicleSchedule();
  }, []);

  return (
    <Paper style={{
      padding: '1vw',
      width: '77vw',
      position: 'sticky',
      right: 0,
      borderRadius: '14px',
      border: '2px solid #003c63',
      marginBottom: '0.8em',
    }}
    >
      <Typography gutterBottom style={{ fontSize: '18px', marginBottom: '20px' }}> VEHICLE SCHEDULE </Typography>
      <GanttChart
        data={schedule}
      />
    </Paper>
  );
};

export default withProps({
  getVehicleSchedule,
  schedule,
})(VehicleSchedule);
