import { useEffect } from 'react';
import {
  select,
  axisLeft,
  scaleLinear,
  axisBottom,
  line,
} from 'd3';

const ScatterPlot = ({ id: _id, data }) => {
  const id = `barplot-${_id}`;
  const margin = {
    top: 20,
    right: 20,
    bottom: 65,
    left: 80,
  };
  const width = 850 - margin.left - margin.right;
  const height = 300 - margin.top - margin.bottom;

  useEffect(() => {
    const svg = select(`#${id}`)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const x = scaleLinear()
      .domain([0, 9])
      .range([0, width]);
    svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(axisBottom(x));

    // Add Y axis
    const y = scaleLinear()
      .domain([0, 32])
      .range([height, 0]);
    svg.append('g')
      .call(axisLeft(y).tickSize(0).tickValues([]));

    const targetGoalArr = [8, 16, 24, 32];

    svg.selectAll('.targetgoal')
      .data(targetGoalArr)
      .enter().append('line')
      .attr('class', 'targetgoal')
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', y)
      .attr('y2', y)
      .style('stroke', (d, i) => i == 0 ? 'black' : '#e1e1e1');

    svg.selectAll('.targetgoal-text')
      .data(targetGoalArr)
      .enter()
      .append('text')
      .style('font-size', '12px')
      .attr('class', 'targetgoal')
      .attr('x', -75)
      .attr('y', (d) => y(d))
      .text((d) => `${d} manhours`);

    svg.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#69b3a2')
      .attr('stroke-width', 3)
      .attr('d', line()
        .x((d) => x(d.persons))
        .y((d) => y(d.manHours)));

    svg.append('text')
      .style('font-size', '16px')
      .attr('class', 'targetgoal')
      .attr('x', width - 55)
      .attr('y', height + 35)
      .text('ppl');
    return () => svg?.node().parentElement.remove();
  }, [data]);

  return (
    <div
      id={id}
      style={{
        maxWidth: 2 * width + margin.left + margin.right,
        maxHeight: height + margin.top + margin.bottom,
      }}
    />
  );
};

export default ScatterPlot;
