import { useEffect, useState } from 'react';
import { Autocomplete, TextField, Paper, Typography } from '@mui/material';
import { DatePicker, Flex } from 'components';
import { withProps } from 'libraries/components';
import {
  getLastDate,
  lastDateData,
  patchDate,
  getFilteredRoutes,
  options,
  routes,
  getFilteredSchedule,
  getFilteredWorkforce,
  getAllRoutes,
} from 'models/app';
import { uniq } from 'lodash';

const SideBarDetails = ({
  date,
  setDate,
  getLastDate,
  lastDateData,
  patchDate,
  getFilteredRoutes,
  routes,
  getFilteredSchedule,
  getFilteredWorkforce,
  getAllRoutes,

}) => {
  const [vehicle, setVehicle] = useState(null);
  const [station, setStation] = useState(null);

  const solutionOptions = [
    'Projected',
    'Actual',
    'Both',
  ];
  const [solution, setSolution] = useState(solutionOptions[0]);

  const newArr = Object
    .entries(routes?.projected || {})
    .map((([vehicle, value], index) => {
      const nodes = value?.routes.flatMap(({ node_sequence }) => {
        return (node_sequence)
      })
      return ({
        name: vehicle,
        nodes: uniq(nodes),
      });
    }));

  useEffect(() => {
    getLastDate();
  }, []);

  useEffect(() => {
    setDate(lastDateData?.last_date);
  }, [lastDateData]);

  const getFormattedDate = (date) => `${(date.getMonth() > 8) ? (date.getMonth() + 1) : (`0${date.getMonth() + 1}`)}-${(date.getDate() > 9) ? date.getDate() : (`0${date.getDate()}`)}-${date.getFullYear()}`;

  const vehicleOptions = newArr
    .filter((datum) => station == null || datum.nodes.includes(station)).map(({ name }) => name);
  const stationOptions = vehicle
    ? newArr.find((datum) => datum.name === vehicle)?.nodes : newArr.flatMap(({ nodes }) => nodes);

  return (
    <Paper style={{
      marginLeft: '0.8em',
      padding: '1vw',
      width: '19vw',
      position: 'sticky',
      left: 0,
      borderRadius: '10px',
      border: '2px solid #003c63',
    }}
    >
      <Typography gutterBottom style={{ fontSize: '18px', marginBottom: '14px' }}> ROUTES </Typography>
      <Flex direction="column" style={{ marginBottom: 24, maxWidth: 300 }}>
        <DatePicker
          disabled
          label="Date"
          variant="outlined"
          date={date ? date.replace(/-/g, '/') : date}
          shouldDisableDate={(date) => lastDateData
            ?.dates?.every((datum) => new Date(datum.date).getTime() !== date.getTime())}
          onChange={(value) => {
            const date = getFormattedDate(new Date(value?.toISOString()));
            setDate(date);
            patchDate(date);
            getFilteredSchedule(date);
            getFilteredWorkforce(date);
          }}
          style={{
            marginBottom: 8,
            marginTop: 12,
            padding: '10em',
            width: '80vw',
          }}
        />
        <Autocomplete
          id="vehicle-autocomplete"
          renderInput={(params) => <TextField {...params} label="Choose Vehicle" />}
          value={vehicle}
          options={vehicleOptions}
          onChange={(_, selectedVehicle) => {
            if (selectedVehicle === null) {
              getAllRoutes();
            }
            setVehicle(selectedVehicle);
            const formDate = date.split('-').join('/');
            selectedVehicle && getFilteredRoutes({
              filter_by: 'vehicle',
              filter_value: selectedVehicle,
              solution: 'projected',
              date: formDate,
            });
          }}
          size="small"
          style={{
            marginBottom: '32px',
            width: '16.2vw',
            backgroundColor: 'white',
            marginTop: '32px',
          }}
        />
        <Autocomplete
          id="station-autocomplete"
          renderInput={(params) => <TextField {...params} label="Choose Station" />}
          value={station}
          options={uniq(stationOptions)}
          onChange={(_, selectedStation) => {
            setStation(selectedStation);
            const formDate = date.split('-').join('/');
            selectedStation && getFilteredRoutes({
              filter_by: 'station',
              filter_value: selectedStation,
              solution: 'projected',
              date: formDate,
            });
          }}
          size="small"
          style={{
            marginBottom: '32px',
            width: '16.2vw',
            backgroundColor: 'white',
          }}
        />
        <Autocomplete
          id="solution-autocomplete"
          renderInput={(params) => <TextField {...params} label="Solution" />}
          value={solution}
          options={solutionOptions}
          disabled
          onChange={(_, selectedSolution) => setSolution(selectedSolution)}
          size="small"
          style={{
            marginBottom: '16px',
            width: '16.2vw',
            backgroundColor: 'white',
          }}
        />
      </Flex>
    </Paper>
  );
};

export default withProps({
  getLastDate,
  lastDateData,
  patchDate,
  getFilteredRoutes,
  routes,
  options,
  getFilteredSchedule,
  getFilteredWorkforce,
  getAllRoutes,
})(SideBarDetails);
