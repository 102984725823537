import {
  BrowserRouter,
  Redirect,
  Switch,
  Route,
} from 'react-router-dom';
import { NavigationBar } from 'components';

import routes, { routesList } from './config';

const Routes = () => (
  <BrowserRouter>
    <NavigationBar routesList={routesList} />
    <Switch>
      {Object.values(routes)
        .filter(({ enabled }) => enabled)
        .map((routeProps, index) => (
          <Route key={index} {...routeProps} />
        ))}

      <Route
        path="*"
        render={() => <Redirect to="/" />}
      />
    </Switch>
  </BrowserRouter>
);

export default Routes;
