import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Stack, TextField } from '@mui/material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/lab';

const DatePicker = ({
  onChange,
  label,
  textfieldProps,
  tileClassName,
  shouldDisableDate,
  date,
}) => {
  const handleChange = (newValue) => {
    onChange(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label={label}
          inputFormat="dd/MM/yyyy"
          value={date}
          tileClassName={tileClassName}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} size="small" {...textfieldProps} />}
          shouldDisableDate={shouldDisableDate}
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default DatePicker;
