import { httpRequest } from 'libraries/system';
import { openSnackbar } from 'models/ui';
import { ofType, combineEpics } from 'redux-observable';
import { map } from 'rxjs/operators';

import * as actions from './actions';
import * as services from './services';

const getAllRoutesEpic = (action$) => action$.pipe(
  ofType(actions.getAllRoutes.type),
  httpRequest(
    action$,
    services.getAllRoutes,
  ),
);

const getLastDateEpic = (action$) => action$.pipe(
  ofType(actions.getLastDate.type),
  httpRequest(
    action$,
    services.getLastDate,
  ),
);

const patchDateEpic = (action$) => action$.pipe(
  ofType(actions.patchDate.type),
  httpRequest(
    action$,
    services.patchDate,
  ),
);

const getFilteredRoutesEpic = (action$) => action$.pipe(
  ofType(actions.getFilteredRoutes.type),
  httpRequest(
    action$,
    services.getFilteredRoutes,
  ),
);

const getWorkforceEpic = (action$) => action$.pipe(
  ofType(actions.getWorkforce.type),
  httpRequest(
    action$,
    services.getWorkforce,
  ),
);

const getFilteredWorkforceEpic = (action$) => action$.pipe(
  ofType(actions.getFilteredWorkforce.type),
  httpRequest(
    action$,
    services.getFilteredWorkforce,
  ),
);

const getVehicleScheduleEpic = (action$) => action$.pipe(
  ofType(actions.getVehicleSchedule.type),
  httpRequest(
    action$,
    services.getVehicleSchedule,
  ),
);

const getFilteredScheduleEpic = (action$) => action$.pipe(
  ofType(actions.getFilteredSchedule.type),
  httpRequest(
    action$,
    services.getFilteredSchedule,
  ),
);

export default combineEpics(
  getAllRoutesEpic,
  getLastDateEpic,
  patchDateEpic,
  getFilteredRoutesEpic,
  getWorkforceEpic,
  getFilteredWorkforceEpic,
  getVehicleScheduleEpic,
  getFilteredScheduleEpic,
);
