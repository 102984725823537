import { Flex, Breadcrumbs } from 'components';
import { withProps } from 'libraries/components';

import { compose } from 'redux';
import { useEffect, useState } from 'react';

import { useComponentMount } from 'libraries/components/hooks';
import { updateSelectedRoute, routes, getAllRoutes } from 'models/app';

import {
  Map,
  SideBarDetails,
  RouteAnalysis,
  VehicleRoute,
  VehicleSchedule,
  WorkForceAnalytics,
} from './components';
import withStyles from './styles';

const Analysis = ({
  updateSelectedRoute,
  getAllRoutes,
}) => {
  const [date, setDate] = useState('');
  const [vehicle, setVehicle] = useState(null);

  useEffect(() => {
    getAllRoutes();
  }, []);

  useComponentMount(() => {
    updateSelectedRoute('analysis');
  });

  return (
    <Flex gap="1vw">

      <Flex direction="column" gap="1vw">
        <Breadcrumbs labels={['Routes Analysis']} />
        <SideBarDetails
          date={date}
          setDate={setDate}
          vehicle={vehicle}
          setVehicle={setVehicle}
        />
        <RouteAnalysis
          date={date}
          vehicle={vehicle}
        />
        <VehicleRoute />
      </Flex>

      <Flex direction="column" gap="1vw">
        <Map />
        <WorkForceAnalytics />
        <VehicleSchedule />
      </Flex>

    </Flex>
  );
};

export default compose(
  withProps({
    updateSelectedRoute,
    routes,
    getAllRoutes,
  }),
  withStyles,
)(Analysis);
