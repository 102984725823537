const styles = (theme) => ({
  container: {
    backgroundColor: '#ae1028',
    color: theme.palette.primary.contrastText,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
  },
  logo: {
    marginBottom: theme.spacing(6),
  },
});

export default styles;
