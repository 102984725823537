import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from './styles';

const Home = ({ classes }) => (
  <div className={classes.container}>
    {/* <Typography variant="h2">Welcome to Synthetica UI Template</Typography> */}
    {/* <Typography variant="body1">
      Edit&nbsp;
      <strong>&apos;/src/routes/home&apos;</strong>
      &nbsp;to get started.
    </Typography> */}
  </div>
);

export default withStyles(styles)(Home);
