import { createReducer } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import * as actions from './actions';

const initialState = {
  routes: {},
  lastDateData: {},
  options: {
    vehicle: [],
    station: [],
  },
  work: [],
  schedule: [],
  details: {},
};

const reducer = createReducer(initialState, (builder) => builder
  .addCase(actions.updateSelectedRoute, (state, { payload }) => {
    state.selectedRoute = payload;
  })
  .addCase(actions.getAllRoutes.success, (state, { payload }) => {
    state.routes = payload;
    state.options.vehicle = Object.keys(payload.projected);
    state.options.station = uniq(payload?.problem?.map(({ node_id }) => node_id));
  })
  .addCase(actions.getLastDate.success, (state, { payload }) => {
    state.lastDateData.dates = payload.dates;
    state.lastDateData.last_date = payload.last_date.split('/').join('-');
  })
  .addCase(actions.patchDate.success, (state, { payload }) => {
    // state.options.truck = Object.keys(payload.projected);
    // state.options.client = uniq(payload?.problem?.map(({ node_id }) => node_id));
    state.routes = payload;
  })
  .addCase(actions.getFilteredRoutes.success, (state, { payload }) => {
    state.routes = payload;
  })
  .addCase(actions.getWorkforce.success, (state, { payload }) => {
    state.work = payload.workforce.map((datum) => ({
      persons: datum.n_people,
      manHours: Number(datum.hours_needed.split(':').slice(0, -1).join('.')),
    }));
    state.details = {
      requiredWorkforce: payload.required_workforce,
      totalWeight: payload.total_weight,
    };
  })
  .addCase(actions.getFilteredWorkforce.success, (state, { payload }) => {
    state.work = payload.workforce.map((datum) => ({
      persons: datum.n_people,
      manHours: Number(datum.hours_needed.split(':').slice(0, -1).join('.')),
    }));
    state.details = {
      requiredWorkforce: payload.required_workforce,
      totalWeight: payload.total_weight,
    };
  })
  .addCase(actions.getVehicleSchedule.success, (state, { payload }) => {
    state.schedule = payload.vehicles.map((datum) => ({
      vehicle_id: `vehicle ${datum.vehicle_id}`,
      dataset: datum.chart_data.map((d) => ({
        ...d,
        start: Number(d.start.split(':').slice(0, -1).join('.')),
        end: Number(d.end.split(':').slice(0, -1).join('.')),
      })),
    }));
  })
  .addCase(actions.getFilteredSchedule.success, (state, { payload }) => {
    state.schedule = payload.vehicles.map((datum) => ({
      vehicle_id: `vehicle ${datum.vehicle_id}`,
      dataset: datum.chart_data.map((d) => ({
        ...d,
        start: Number(d.start.split(':').slice(0, -1).join('.')),
        end: Number(d.end.split(':').slice(0, -1).join('.')),
      })),
    }));
  }));

export default reducer;
