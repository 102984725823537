import { of, pipe, defer } from 'rxjs';
import { ajax } from 'rxjs/ajax';

import {
  mergeMap,
  catchError,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

// import { authorizationError, getAuthToken } from 'model/user';

import { createAction } from './actions';

// export const serverError = () => catchError((error, source) => {
//   const { status, message } = error;

//   if (status === 401) {
//     return of(authorizationError(source));
//   }

//   if (status === 403) {
//     return of(getAuthToken.fail(source));
//   }

//   return of(({
//     type: 'SERVER_ERROR',
//     message,
//   }));
// });

export const httpRequest = (source, service, options = {}) => pipe(
  withLatestFrom(source),
  switchMap(([data, { type, payload: sourcePayload }]) => {
    const {
      additionalSuccessActions = () => [],
      additionalFailureActions = () => [],
      operators = () => [],
    } = options;

    const successActions = [];
    const failureActions = [];

    const successAction = createAction(`${type}_SUCCESS`);
    const failureAction = createAction(`${type}_FAIL`);

    return defer(() => ajax(service(data))).pipe(
      ...operators(),
      mergeMap(({ response }) => {
        const { success, payload } = response;

        if (success) {
          successActions.push(successAction(payload));
          successActions.push(...additionalSuccessActions(payload, sourcePayload));
        } else {
          failureActions.push(failureAction(payload));
          failureActions.push(...additionalFailureActions(payload, sourcePayload));
        }

        successActions.filter(Boolean);
        failureActions.filter(Boolean);

        return response.success ? successActions : failureActions;
      }),
      // serverError(source),
    );
  }),
);
